import { TECH } from "content/tech-stack";
import { Link } from "components/link";

export const CV = {
  name: "Darshan Parbadiya",
  title: "Fullstack Engineer",
  primary_stack: [TECH.typescript, TECH.react, TECH.nodejs, TECH.tailwindcss, TECH.mysql],
  email: "parbadid@mcmaster.ca",
  mobile: {
    href: `tel:+4376777727`,
    number: "+1(437)-677-7727",
  },

  experience: [
    {
      dateFrom: "2024/05/01",
      dateTo: "Present",
      city: "Ontario",
      country: "Canada",
      company: "Portland Investment Counsel",
      title: "Business Systems Analyst, Co-op",
      type: ["business analysis", "software development", "data analysis", "relevant"],
      description: "",
      responsibilities: [
        {
          content:
            "Led the development of full-stack applications, microservices, and Salesforce applications to manage financial transaction data, leveraging a wide range of frameworks including Next.js, Spring Boot, and Express to efficiently store and query large datasets.",
          type: ["software development", "relevant"],
        },

        {
          content:
            "Analyzed financial data and created a Salesforce dashboard utilizing SOQL queries, Einstein Analytics, and Power BI integration for comprehensive data insights.",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        {
          content:
            "Adopted tRPC to optimize microservices communication, streamlining asynchronous data processing, enabling real-time notifications, and reducing latency for portfolio management and client updates, while utilizing bi-directional streaming for efficient data synchronization across services.",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        {
          content:
            "Implemented microservices to support asynchronous data processing, creating worker processes to handle large volumes of data and sweeper processes to identify and automatically retry failed operations, ensuring efficient and reliable application performance.",
          type: ["data analysis", "business intelligence", "relevant"],
        },
        {
          content:
            "Employed test-driven development (TDD) practices to build reliable, bug-free solutions, and maintained automated test suites for consistent regression testing.",
          type: ["software development", "quality assurance", "relevant"],
        },
        {
          content:
            "Ensured thorough testing, documentation, and deployment of solutions, maintaining high standards for system performance and security.",
          type: ["quality assurance", "documentation", "relevant"],
        },
      ],
    },
    {
      dateFrom: "2024/01/01",
      dateTo: "2024/05/01",
      city: "Ontario",
      country: "Canada",
      company: "Direct Cell",
      title: "Technical Specialist, Part Time",
      description: "",
      type: ["technical support", "hardware/software repair", "customer service", "relevant"],
      responsibilities: [
        {
          content:
            "Diagnosed and repaired a wide range of electronic devices, including phones, tablets, laptops, and gaming consoles, addressing both hardware and software issues.",
          type: ["hardware repair", "software troubleshooting", "relevant"],
        },
        {
          content: "Built strong client relationships through excellent service and support.",
          type: ["customer service", "client relations", "relevant"],
        },
      ],
    },
    {
      dateFrom: "2022/07/01",
      dateTo: "2023/08/01",
      city: "Surat",
      country: "India",
      company: "Epsilon Tech",
      title: "Application Developer, Software Engineering",
      description: "",
      type: ["software development", "front-end development", "web / tech dev", "relevant"],
      responsibilities: [
        {
          content:
            "Developed responsive websites and web applications for diverse clients, enhancing user engagement by 40% through JavaScript frameworks like React.js and Node.js.",
          type: ["web development", "user experience", "relevant"],
        },
        {
          content:
            "Applied front-end best practices, optimizing seamless integration with back-end systems and boosting user satisfaction scores by 30%.",
          type: ["software development", "user experience", "relevant"],
        },
      ],
    },
  ],
  capabilities: {
    languages: [
      {
        name: "English - bilingual",
        Icon: null,
      },
      {
        name: "Gujarati - native",
        Icon: null,
      },
    ],
    programmingLanguages: [
      TECH.typescript,
      TECH.javascript,
      TECH.html_5,
      TECH.css_3,
      TECH.sass,
      TECH.graphql,
      TECH.markdown,
      TECH.liquid,
      TECH.sql,
      TECH.ruby,
      TECH.php,
    ],
    librariesFrameworks: [
      TECH.react,
      TECH.tailwindcss,
      TECH.nextjs,
      TECH.trpc,
      TECH.prisma,
      TECH.react_query,
      TECH.remix,
      TECH.jest,
      TECH.turborepo,
      TECH.nodejs,
      TECH.express,
      TECH.webpack,
      TECH.framer,
      TECH.redis,
    ],
    serviceProviders: [
      TECH.vercel,
      TECH.aws,
      TECH.firebase,
      TECH.netlify,
      TECH.planetscale,
      TECH.github,
    ],
    marketing: [TECH.vercel, TECH.aws, TECH.firebase, TECH.netlify, TECH.planetscale, TECH.github],
    dataProviders: [TECH.shopify, TECH.vend, TECH.erply, TECH.dear, TECH.micros, TECH.wordpress],
    tools: [
      TECH.intellij_idea,
      TECH.git,
      TECH.yarn,
      TECH.npm,
      TECH.illustrator,
      TECH.photoshop,
      TECH.figma,
      TECH.postman,
      TECH.ftp,
      TECH.office,
      TECH.dev_tools,
    ],
  },
  education: [
    {
      dateFrom: "2023/09/01",
      dateTo: "2025/09/01",
      city: "Hamilton",
      country: "Canada",
      company: "McMaster University",
      title: "M.Eng, Systems And Technology (Co-op Program)",
      type: ["education", "relevant", "deep learning", "AI/ML"],
      description: "",
      responsibilities: [
        {
          content:
            "Relevant Courses: Deep Learning, AI ML fundamentals, AI ML classification models, Multivariate statistics.",
          type: ["software development", "relevant"],
        },
      ],
    },
    {
      dateFrom: "2017/06/01",
      dateTo: "2021/05/01",
      city: "India",
      country: "India",
      company: "Gujarat Technological University",
      title: "B.Tech, Computer Engineering; CGPA: 9.12/10",
      type: ["education", "relevant", "computer engineering"],
      description: "",
      responsibilities: [
        {
          content:
            "Relevant Courses: Compiler design, DBMS, Theory of Computation, Computer Networks",
          type: ["software development", "relevant"],
        },
      ],
    },
  ],
};
